<template>
  <div
    class="flex flex-col py-5 bg-lightGrey rounded-xl text-grey justify-center"
  >
    <div class="flex flex-col m-auto gap-y-5 mb-3 w-full px-5">
      <span class="self-start text-2xl font-bold mt-5">{{ title }}</span>
      <v-select
        v-model="filterSelection"
        class="filterDropDown"
        placeholder="Search By"
        label="customLabel"
        :options="filters"
      ></v-select>
      <v-select
        v-if="roleSelected"
        :options="getRoles"
        label="label"
        :filterable="true"
        :placeholder="'Please select...'"
        @open="onOpen"
        @close="onClose"
        v-model="inputSearchValue"
        :class="
          v$.inputSearchValue.$error === true
            ? 'filterDropDown-error'
            : 'filterDropDown'
        "
      >
        <template #list-footer>
          <li v-show="rolesHasNextPage" ref="load" class="loader">
            Loading more options...
          </li>
        </template>
      </v-select>
      <input
        v-else
        :placeholder="placeholderValue"
        v-model="inputSearchValue"
        type="text"
        class="border rounded-md p-2"
        :class="
          v$.inputSearchValue.$error === true ? 'border-red' : 'border-darkGrey'
        "
      />
    </div>
    <div class="flex justify-center items-center">
      <base-button
        @click="submitSearch"
        backgroundColour="bg-orange hover:bg-orange-light"
        >Search</base-button
      >
      <base-button @click="resetSearch">Reset</base-button>
    </div>
    <template v-if="!loadingUsers">
      <div
        class="w-3/4 mx-auto overflow-x-auto h-36"
        v-if="searchResponse?.data"
      >
        <ul class="list-disc ml-5">
          <li
            v-for="user in searchResponse?.data"
            :key="user.id"
            class="hover:underline cursor-pointer"
            @click="$emit('user-details', user)"
          >
            {{
              `${user?.profile?.first_name} ${user?.profile?.last_name} (${user?.email})`
            }}
          </li>
        </ul>
        <my-pagination
          v-if="searchResponse"
          :options="options"
          v-model="page"
          :records="searchResponse.total"
          :per-page="searchResponse.per_page"
          @paginate="myCallback"
        >
        </my-pagination>
      </div>
    </template>
    <base-spinner v-else />
  </div>
</template>

<script>
import BaseButton from "@/components/ui/baseComponents/BaseButton.vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import { markRaw } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import BaseSpinner from "../../../../ui/baseComponents/BaseSpinner.vue";
export default {
  components: { BaseButton, BaseSpinner },
  props: {
    data: {
      type: Object,
      // required: true,
    },
    searchResponse: {
      type: Object,
      // required: true,
    },
    title: {
      type: String,
      required: true,
    },
    loadingUsers: {
      type: Boolean,
    },
  },
  created() {
    this.loadRoles(this.rolesPage);
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  data() {
    return {
      page: 1,
      options: {
        template: markRaw(customPaginate),
      },
      v$: useVuelidate(),
      placeholderValue: "Select Filter",
      filterSelection: null,
      inputSearchValue: "",
      roleValue: null,
      roleSelected: false,
      rolesPage: 1,
      roleData: [],
      observer: null,
      payload: [],
      filters: [
        {
          customLabel: "Role",
          value: "role",
        },
        {
          customLabel: "Email",
          value: "email",
        },
        {
          customLabel: "Last Name",
          value: "last_name",
        },
        {
          customLabel: "Mobile Phone",
          value: "mobile_phone",
        },
      ],
    };
  },
  validations() {
    return {
      filterSelection: { required },
      inputSearchValue: { required },
    };
  },
  methods: {
    myCallback(e) {
      this.$emit("paginate-search-results", [this.payload, e]);
    },
    resetSearch() {
      this.filterSelection = null;
      this.roleSelected = false;
      this.inputSearchValue = "";
      this.placeholderValue = "Select Filter";
    },
    submitSearch() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.payload = [];
        if (this.filterSelection.value === "role") {
          this.payload.push(
            this.filterSelection.value,
            this.inputSearchValue.code
          );
        } else {
          this.payload.push(this.filterSelection.value, this.inputSearchValue);
        }
        this.$emit("search-user", { filter: this.filterSelection.value, value: this.inputSearchValue});
      } else {
        console.log("ERRORRR");
      }
    },
    async onOpen() {
      if (this.rolesHasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        this.rolesPage++;
        this.loadRoles(this.rolesPage);
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async loadRoles(rolesPage) {
      try {
        await this.$store.dispatch("admin/loadRoles", rolesPage);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
  watch: {
    loadingUsers() {
      console.log("STA: ", this.loadingUsers);
    },
    filterSelection() {
      this.inputSearchValue = "";
      if (this.filterSelection) {
        switch (this.filterSelection.value) {
          case "role":
            return (this.roleSelected = true);
          case "email":
            this.roleSelected = false;
            return (this.placeholderValue =
              "Enter individuals email address...");
          case "last_name":
            this.roleSelected = false;
            return (this.placeholderValue =
              "Enter individuals last name address...");
          case "mobile_number":
            this.roleSelected = false;
            return (this.placeholderValue =
              "Enter individuals mobile Number address...");
        }
      } else {
        return (this.roleSelected = false);
      }
    },
  },
  computed: {
    getRoles() {
      return this.$store.getters["admin/getRoles"];
    },
    rolesHasNextPage() {
      // Determine how many pages
      const data = this.$store.getters["admin/getRoles"];
      const totalPages = Math.ceil(data.total / 10);
      if (this.rolesPage <= totalPages) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.filterDropDown .vs__dropdown-toggle,
.filterDropDown .vs__dropdown-menu {
  border: 1px solid #425563;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}
.filterDropDown .vs__dropdown-toggle {
  width: 100%;
}
#filterDropDown .filterDropDown .vs__clear,
#filterDropDown .filterDropDown .vs__open-indicator {
  fill: black;
}
.filterDropDown-error .vs__dropdown-toggle,
.filterDropDown-error .vs__dropdown-menu {
  border: 1px solid red;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}
.filterDropDown-error .vs__dropdown-toggle {
  width: 100%;
}
#filterDropDown-error .filterDropDown .vs__clear,
#filterDropDown-error .filterDropDown .vs__open-indicator {
  fill: black;
}
</style>
