<template>
  <div
    class="flex flex-col w-full my-2 bg-lightGrey rounded-xl text-grey justify-center"
  >
    <div class="w-3/4 flex flex-col m-auto gap-y-2 mb-3">
      <div class="self-start text-xl font-bold mt-5" @click="submit">
        Select Interview type
      </div>
      <div class="flex justify-between">
        <div class="flex flex-col">
          <div>
            <input
              class="mr-2"
              type="radio"
              id="digital-interview"
              value="digital-interview"
              v-model="locationType"
            />

            <label
              for="digital-interview"
              :class="
                v$.locationType.$error === true ? 'text-red' : 'text-grey'
              "
              >Digital</label
            >
          </div>
          <div>
            <input
              class="mr-2"
              type="radio"
              id="physical-interview"
              value="physical-interview"
              v-model="locationType"
            />
            <label
              for="physical-interview"
              :class="
                v$.locationType.$error === true ? 'text-red' : 'text-grey'
              "
              >Physical</label
            >
          </div>
        </div>
        <div class="flex flex-col">
          <div>
            <input
              class="mr-2"
              type="radio"
              id="first-interview"
              value="first-interview"
              v-model="localInterviewNumber"
            />
            <label
              for="first-interview"
              :class="
                v$.interviewNumber.$error === true ? 'text-red' : 'text-grey'
              "
              >First</label
            >
          </div>
          <div>
            <input
              class="mr-2"
              type="radio"
              id="second-interview"
              value="second-interview"
              v-model="localInterviewNumber"
            />
            <label
              for="second-interview"
              :class="
                v$.interviewNumber.$error === true ? 'text-red' : 'text-grey'
              "
              >Second</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  props: {
    locationData: {
      type: String,
      required,
    },
    interviewNumber: {
      type: String,
      required,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      locationType: this.locationData,
      localInterviewNumber: this.interviewNumber,
    };
  },
  validations() {
    return {
      locationType: { required },
      interviewNumber: { required },
    };
  },
  watch: {
    locationType() {
      return this.$emit("location-type-change", this.locationType);
    },
    localInterviewNumber() {
      return this.$emit("interview-number-change", this.localInterviewNumber);
    },
  },
  methods: {
    submit() {
      this.v$.$validate();
      this.$emit("interview-number");
    },
  },
};
</script>
