<template>
  <div
    class="flex items-center text-teal ml-5 mt-3 cursor-pointer w-24"
    @click="reRoute"
  >
    <span class="material-icons-outlined"> arrow_back </span>
    go Back
  </div>
  <screen-split-large-left>
    <template v-slot:leftSide>
      <CandidateSearch
        v-if="!hasUserID"
        class="w-3/4 py-5"
        title="Candidates Search"
        @search-user="searchUser"
        @user-details="showUserDetails"
        @paginate-search-results="paginatedSearch"
        :searchResponse="getUsers"
        :loadingUsers="isLoadingUsers"
      />
      <div class="w-3/4 pt-5">
        <CandidateHiringRequestDetails
          v-if="isLoadingHiringRequest"
          :data="getHiringRequest"
        />
        <template v-if="detailsArray.length > 0">
          <mini-profile-card :data="detailsArray" class="border" />
        </template>
        <no-data-found v-else />
      </div>
      <div></div>
    </template>
    <template v-slot:rightSide>
      <div class="flex justify-between gap-2 my-5">
        <toggle-component
          label="Additional Staff Member?"
          labelID="hqReq"
          checkedColour="bg-teal"
          @toggle-status="toggleAdditionalStaff"
        />
        <toggle-component
          label="HQ required?"
          labelID="additionalStaff"
          checkedColour="bg-orange"
          @toggle-status="toggleHQ"
        />
      </div>
      <InterviewType
        :locationData="formData.locationType"
        :interviewNumber="formData.interviewNumber"
        @location-type-change="locationTypeChange"
        @interview-number-change="interviewNumberChange"
      />
      <transition appear>
        <AdditionalStaffMemberSearch
          v-if="additionalStaffMember"
          title="Is additional staff member required for this interview?"
          @set-additional-staff-member="setAdditionalStaffMember"
        />
      </transition>
      <transition appear>
        <HQRequired
          v-if="hqNeeded"
          title="Is HQ required for this interview?"
          @set-hq-required="setHqRequired"
        />
      </transition>
      <ScheduleInterview
        @submit-form="submitNewInterview"
        title="Schedule Interview"
      />
    </template>
  </screen-split-large-left>
</template>
<script>
import { markRaw } from "vue";

import screenSplitLargeLeft from "@/components/ui/baseComponents/screenSplitLargeLeft.vue";
import ContactError from "@/components/ui/baseComponents/errorComponent.vue";
import miniProfileCard from "@/components/ui/baseComponents/miniProfileCard.vue";
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";
import AdditionalStaffMemberSearch from "../../../components/layout/RE/New_Interview/Right_Side/AdditionalStaffMemberSearch.vue";
import HQRequired from "../../../components/layout/RE/New_Interview/Right_Side/HQRequired.vue";
import InterviewType from "../../../components/layout/RE/New_Interview/Right_Side/InterviewType.vue";
import ScheduleInterview from "../../../components/layout/RE/New_Interview/Right_Side/ScheduleInterview.vue";
import CandidateSearch from "../../../components/layout/RE/New_Interview/Left_Side/CandidateSearch.vue";
import CandidateHiringRequestDetails from "../../../components/layout/RE/New_Interview/Left_Side/CandidateHiringRequestDetails.vue";
import ToggleComponent from "@/components/ui/baseComponents/ToggleComponent.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Swal from "sweetalert2";

export default {
  async mounted() {
    if (this.$route.params?.id) {
      const {
        data: { user },
      } = await this.$store.dispatch("REnewHire/fetchUserData", {
        user: this.$route.params.id,
      });
      this.userDetails = user;
      await this.fetchHiringRequest(user.profile.hiring_request_id);
      this.detailsArray = [];
      this.detailsArray.push(this.userDetails);
      this.isLoadingHiringRequest = true;
      this.formData.user = this.$route.params.id;
    }
  },
  components: {
    ToggleComponent,
    noDataFound,
    miniProfileCard,
    screenSplitLargeLeft,
    AdditionalStaffMemberSearch,
    HQRequired,
    InterviewType,
    ScheduleInterview,
    CandidateSearch,
    CandidateHiringRequestDetails,
  },
  data() {
    return {
      page: 1,
      v$: useVuelidate(),
      formData: {
        user: null,
        locationType: "digital-interview",
        interviewNumber: "first-interview",
        selectedAdditionalUser: null,
        selectedHQUser: null,
        interviewDate: null,
        interviewTime: null,
        interviewDuration: null,
        location: null,
      },
      isLoadingUsers: false,
      isLoadingHiringRequest: false,
      profileIsLoading: false,
      userDetails: null,
      detailsArray: [],
      searchResultsPage: 1,
      fallBack: markRaw(ContactError),
      additionalStaffMember: false,
      hqNeeded: false,
    };
  },
  validations() {
    return {
      formData: {
        user: { required },
        locationType: { required },
        interviewNumber: { required },
        interviewDate: { required },
        interviewTime: { required },
        interviewDuration: { required },
      },
    };
  },
  methods: {
    reRoute() {
      this.$router.back();
    },
    locationTypeChange(data) {
      this.formData.locationType = data;
    },
    interviewNumberChange(data) {
      this.formData.interviewNumber = data;
    },
    setAdditionalStaffMember(data) {
      this.formData.selectedAdditionalUser = data;
    },
    setHqRequired(data) {
      this.formData.selectedHQUser = data;
    },
    async submitNewInterview(data) {
      this.formData.interviewDate = data.date;
      this.formData.interviewTime = data.time;
      this.formData.interviewDuration = data.duration;
      this.v$.$validate();
      try {
        const response = await this.$store.dispatch(
          "REgeneralModule/createInterview",
          {
            practice: this.getHiringRequest.department.practice_id,
            department: this.userDetails.department_id,
            user: this.formData.user,
            hiring_request: this.userDetails.profile.hiring_request_id,
            date: this.formData.interviewDate,
            time: this.formData.interviewTime,
            location: this.formData.location,
            interview_type: this.formData.locationType,
            application_status: this.formData.interviewNumber,
            additional_staff: this.formData.selectedAdditionalUser,
            hq_staff: this.formData.selectedHQUser,
            duration: this.formData.interviewDuration,
          }
        );
        if (response.status === 201) {
          Swal.fire({
            title: "Done",
            text: "Successfully Scheduled the Interview",
            icon: "success",
          });
          this.$router.push({
            name: "re-interviews-offers",
            query: { success: "true" },
          });
        }
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        Swal.fire({
          title: "Failed",
          text: "Cannot Create the Interview",
          icon: "error",
        });
      }
    },
    toggleAdditionalStaff(status) {
      this.additionalStaffMember = status;
    },
    toggleHQ(status) {
      this.hqNeeded = status;
    },
    async paginatedSearch(payload) {
      this.searchResultsPage = payload[1];
      this.searchUser(payload[0], this.searchResultsPage);
    },
    async searchUser({ filter, value }) {
      console.log("test: ", filter);
      this.isLoadingUsers = true;
      await this.$store.dispatch("REgeneralModule/loadUsers", {
        [filter]: value.value ? value.value : value.code ? value.code : value,
      });
      this.isLoadingUsers = false;
    },
    async showUserDetails(data) {
      this.profileIsLoading = true;
      this.userDetails = data;
      this.formData.user = this.userDetails.id;
      this.detailsArray = [];
      this.detailsArray.push(this.userDetails);
      console.log("details array: " + this.detailsArray, this.userDetails);
      await this.fetchHiringRequest(data.profile.hiring_request_id);
      this.profileIsLoading = false;
    },
    async fetchHiringRequest(requestFormId) {
      this.profileIsLoading = true;
      try {
        await this.$store.dispatch("HQnewHire/fetchHiringRequest", {
          hiring_request: requestFormId,
        });
        this.isLoadingHiringRequest = true;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.profileIsLoading = false;
    },
  },
  computed: {
    getHiringRequest() {
      return this.$store.getters["HQnewHire/getHiringRequest"];
    },
    getUsers() {
      return this.$store.getters["REgeneralModule/getUsers"];
    },
    hasUserID() {
      return this.$route.params.id;
    },
  },
};
</script>
