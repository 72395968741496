<template>
  <div class="flex w-full mb-2">
    <div class="text-center text-grey bg-white rounded-xl ml-2 shadow-md">
      <p class="text-grey font-semibold text-lg mx-3">
        {{ data.contract_type.toUpperCase() }}
      </p>
      <div class="relative">
        <span class="material-icons text-7xl mx-3" :class="iconClass">
          person
        </span>
      </div>
    </div>
    <div
      class="bg-white rounded-xl w-full flex flex-col mx-2 shadow-md gap-y-1"
    >
      <h3 class="font-bold text-lg pl-2">Request Information</h3>
      <div class="flex pl-2">
        <p>
          Job title:
          <span>{{ capitalizeFirstLetter(data.job_title) }}</span>
        </p>
      </div>
      <div class="flex pl-2">
        <p>
          Reports to:
          {{ capitalizeFirstLetter(data.reporting_to) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  computed: {
    iconClass() {
      return {
        "text-teal": this.data.contract_type === "Permanent",
        "text-orange": this.data.contract_type === "Casual",
        "text-green": this.data.contract_type === "Fixed-term",
        "text-red": this.data.contract_type === "Zero-hour",
      };
    },
  },
};
</script>
